import React, {useEffect} from 'react'
import {Helmet} from "react-helmet"
import aboutVideo from '../Assets/about_two.MP4';
import meir from '../Assets/meir.png';
import oshrit from '../Assets/oshrit.png';
import rav from '../Assets/rav.png';
import AboutIcon from '../components/AboutIcon';

function About() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
  <>
  <div className='about'>
  <h1>אודותינו</h1>
    <p>
      המייזם הוקם בעקבות הטבח הנוראי שארע בכ"ב תשרי התשפ"ד ב-7 לאוקטובר. אלפי משפחות התחילו לקבל על עצמן לשמור שבת למען עם ישראל, החיילים והחטופים שלנו.
      הודעה אחת קטנה שנשלחה לאושרית אלישע מיהודיה שרצתה רק פלטה אחת לשבת משם התחיל לו מייזם ענק.
    </p>
    <p>
      המייזם הוקם על ידי מזכת הרבים אושרית אלישע, נטורופטית וקוסמטיקאית רפואית במקצועה, ומאיר רפאל חי ביטון, בסיוע מזכת הרבים שולמית חוה.
      בזכות המייזם, יוצר מערכת עזר אמיצה שכוללת: פלטה, מיחם, כוס קידוש, פמוטים, כיסוי חלות, סידור, נטלה, וספר הדרכה הלכתי למתחיל. ניתן גם לקבל ידיעות ומדריך מקצועי לשמירה על השבת.
    </p>
    <p>
      אני מודה לה' על הזכות שנתן לי ביחד עם המייסדת והשותפים למייזם שמירת השבת הרבנית אושרית אלישע. תודה לחברי עו"ד יוני ג'ורנו, למעצבת הגרפית אסתר עיצובים ולכל המתנדבים.
      תודה רבה על עבודת הקודש שאתם עושים ופועלים למען עם ישראל בימים קשים אלו.
    </p>
    <div className='center'>
        <video controls width="auto" height="320px">
        <source src={aboutVideo} />
        Your browser does not support the video tag.
        </video>
      </div>
    <p>
      הצלחנו להגיע למאות ערכות שבת שחולקו בחינם למשפחות בישראל מכל סוגי הרקעים. בתקופה קצרה, נצלנו לעזור לניצולי הטבח האכזרי, ניצולי נובה ולכל יהודי שרצה לקבל על עצמו שמירת שבת.
    </p>
    <p>
      במהלך 14 ימים לבד, חלקנו יותר מ-700 ערכות שבת בשווי של כ-198 אלף ש"ח. כל זאת עם יחד עם ישראל, האהובים, שהשתדלו לתרום ולסייע בימים קשים אלו.
    </p>
    <p>
      אנחנו לקראת סיום המלאי של ערכות השבת וחייבים את השיתופים שלכם בבקשה אנחנו רוצים להמשיך לזכות עוד כ 4,300 איש שנרשמו ועוד לא הספיקו לקבל את הערכה.
בעקבות ריבוי הבקשות לקבלת שמירת השבת אנו פונים אליכם עם ישראל היקר, בואו נעזור למי שרוצה לשמור שבת כהלכתה!
    </p>
  <h1 style={{textAlign:'center'}}>מחברי האירגון</h1>
  <div className='center_about'>
    <AboutIcon img={rav} letter="hello" name="הרה''ג הראל אשר יזדי שליט''א" bio="מומחה בתחום הכשרות והשבת ופוסק הלכתי בארגון אמל''ח שבת לישראל." />
    <AboutIcon img={oshrit} name="הרבנית אושרית אלישע - מושב צרופה" bio="נטורופטית וקוסמטיקאית רפואית." />
    <AboutIcon img={meir} name="מאיר רפאל חי ביטון - נהריה" bio="פועל בתחום הכשרות מזה כ 12 שנה מפקח כשרות ומסייע לבעלי עסקים בתחום הכשרות בארץ ובעולם." />
  </div>
  </div>
  <Helmet>
    <title>אמל"ח שבת לישראל - אודותינו</title>
  </Helmet>
  </>
  )
}

export default About