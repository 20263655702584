import React, {useEffect} from 'react'
import {Helmet} from "react-helmet"
import Welcome from '../home-section/Welcome'
import Testimonials from '../home-section/Testimonials'
import Donation from '../home-section/Donation'
import Shop from '../home-section/Shop'

function HomePage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <Welcome />
      <Testimonials />
      <Donation />
      <Shop />
      <Helmet>
        <title>אמל"ח שבת לישראל - ראשי</title>
      </Helmet>
    </>
  )
}

export default HomePage;