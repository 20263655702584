import React, {useState} from 'react'
import letterImg from'../Assets/letter.jpeg'

function AboutIcon({img,name,bio,letter}) {
  const [displayImg,setDisplayImg] = useState(false);

  return (
    <div className='about_icon'>
      <img src={img} />
      <p className='name'>{name}</p>
      <p className='bio'>{bio}</p>
      {
        letter && <div className='center'><button onClick={()=>setDisplayImg(true)}>מכתב המלצה</button></div>
      }
      {displayImg &&
        <div className='letter_img'>
          <button onClick={()=>setDisplayImg(false)}>סגירה</button>
          <img src={letterImg} />
        </div>
      }
    </div>
  )
}

export default AboutIcon