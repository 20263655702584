import React, {useEffect} from 'react'
// import ImageSlider from '../components/ImageSlider'
import VideoSlider from '../components/VideoSlider';

function Projects() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='projects'>
      <div className='gallery center'>
        <h2>קצת מהתגובות של מאות אנשים שלקחו כבר על עצמם לשמור שבת קודש!</h2>
        {/* <ImageSlider /> */}
        <VideoSlider />
      </div>
    </div>
  )
}

export default Projects