import './App.css';
import {Routes,Route} from 'react-router-dom'
import HomePage from './pages/HomePage';
import About from './pages/About';
import Projects from './pages/Projects';
import Shop from './pages/Shop';
import Donations from './pages/Donations';
import Error from './pages/Error';
import NavBar from './pages/NavBar';
import Footer from './home-section/Footer'

function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/projects" element={<Projects/>}/>
        <Route path="/shop" element={<Shop/>}/>
        <Route path="/donations" element={<Donations/>}/>
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
