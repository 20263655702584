import React, { useState, useEffect } from 'react';
import ImageSlider from './ImageSlider';
import videoOne from '../Assets/projects_video.mp4'
import videoTwo from '../Assets/projects_video_two.mp4'
import videoThree from '../Assets/projects_video_three.mp4'
import videoFour from '../Assets/projects_video_four.mp4'
import videoFive from '../Assets/projects_video_five.mp4'
import videoSix from '../Assets/projects_video_six.mp4'
import videoSeven from '../Assets/projects_video_seven.mp4'
import videoEight from '../Assets/projects_video_eight.mp4'

function Video({src}) {
  return(
      <video controls className='projects_video'>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
  )
};

const VideoSlider = () => {

  return (
    <>
    <div className="image-slider center">
      <Video src={videoEight} />
      <Video src={videoSix} />
      <Video src={videoSeven} />
      <Video src={videoFive} />
    </div>
    <ImageSlider />
    <div className="image-slider center">
      <Video src={videoOne} />
      <Video src={videoTwo} />
      <Video src={videoThree} />
      <Video src={videoFour} />
    </div>
    </>
  );
};

export default VideoSlider;
