import React, {useEffect} from 'react'
import {Helmet} from "react-helmet"
import Donation from '../home-section/Donation'
import donOne from '../Assets/don_one.png'
import donTwo from '../Assets/don_two.png';
import donThree from '../Assets/don_three.png';
import donFour from '../Assets/don_four.png';
import donSix from '../Assets/don_six.png';
import donEight from '../Assets/don_eight.png';


function Donations() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='donations_page'>
    <div className='bg_main'>
    <h2>הצטרפו למייזם האדיר ותיהיו שותפים לאלפים מבני ישראל הלוקחים על עצמם לשמור שבת!</h2>
    <div className='center'>
    <div className='donations_choices'>
      <a href="https://payboxapp.page.link/kR6Nzjqxbp1pdduu6"><img src={donOne} /></a>
      <a href="https://payboxapp.page.link/kR6Nzjqxbp1pdduu6"><img src={donTwo} /></a>
      <a href="https://payboxapp.page.link/kR6Nzjqxbp1pdduu6"><img src={donThree} /></a>
      <a href="https://payboxapp.page.link/kR6Nzjqxbp1pdduu6"><img src={donFour} /></a>
      <a href="https://payboxapp.page.link/kR6Nzjqxbp1pdduu6"><img src={donSix} /></a>
      <a href="https://payboxapp.page.link/kR6Nzjqxbp1pdduu6"><img src={donEight } /></a>
    </div>
    </div>
    </div>
    {/* <h2 style={{margin:'10px',marginBottom:'-30px'}} className='donations_subtitle'>בתרומה ע"ס 280 ש"ח לכל ערכה תהיו שותפים למיזם ובזכותכם משפחות נוספות יקבלו ערכה לשמירת שבת!</h2> */}
    <Donation/>
    <Helmet>
      <title>אמל"ח שבת לישראל - תרומות</title>
    </Helmet>
    </div>
  )
}

export default Donations