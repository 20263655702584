import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import '../App.css';
import logoImage from '../Assets/logo.png';
import menuImage from '../Assets/menu.png';
import closeImage from '../Assets/close.png';


function NavBar() {
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false)

  function handleMenu(state) {
    if (state) {
      setDisplayMobileMenu(false)
    } else {
      setDisplayMobileMenu(true)
    }
  };

  return (
    <>
      <header>
        <Link to="/" className='pt_5'><img src={logoImage} alt="logo" /></Link>
        <div className="link_navbar">
          <Link to="/">ראשי</Link>
          <Link to="/about">אודותינו</Link>
          <Link to="/projects">פעילויות</Link>
          <Link to="/shop">קבלת ערכה</Link>
          <Link to="/donations">תרומות</Link>
        </div>
        <div className="btn_navbar">
          <a href='https://wa.me/972545214473'>יצירת קשר</a>
        </div>
      </header>

      <div className="mobile_header">
        <Link to="/" className='pt_5'><img src={logoImage} alt="logo" /></Link>
        {
          !displayMobileMenu && <img src={menuImage} alt="menu" className="logo_mobile" onClick={()=>handleMenu(false)}/>
        }
        {
          displayMobileMenu && <img src={closeImage} alt="close" className="logo_mobile" onClick={()=>handleMenu(true)}/>
        }
      </div>
      {
        displayMobileMenu && 
        
      <div className="link_navbar_mobile">
          <div className='mobile_div' onClick={()=>handleMenu(true)}><Link to="/">ראשי</Link></div>
          <div className='mobile_div' onClick={()=>handleMenu(true)}><Link to="/about">אודותינו</Link></div>
          <div className='mobile_div' onClick={()=>handleMenu(true)}><Link to="/projects">פעילויות</Link></div>
          <div className='mobile_div' onClick={()=>handleMenu(true)}><Link to="/shop">קבלת ערכה</Link></div>
          <div className='mobile_div' onClick={()=>handleMenu(true)}><Link to="/donations">תרומות</Link></div>
      </div>
      }
    </>
  );
}

export default NavBar;
