import React from 'react';
import { Link } from 'react-router-dom';
import nerotImage from '../Assets/nerot.png';
import kidoushImage from '../Assets/kidoush.png';
import eauImage from '../Assets/eau.png';
import plataImage from '../Assets/plata.png';

function ShopComponent({src,text}) {
    return(
        <div className="articles">
            <div className="img_container"><img src={src} alt="img" /></div>
            <h3>{text}</h3>
            <Link to="/shop">הזמנה</Link>
        </div>
    )
}

function Shop() {
  return (
    <>
      <section className="shop_section">
        <h1>הזמן בחינם את מה שחסר לך לעשות שבת מושלמת!</h1>
        <div className="sub_shop">
          <ShopComponent src={nerotImage} text="פמוטים לנרות" />
          <ShopComponent src={kidoushImage} text="כוס קידוש" />
          <ShopComponent src={eauImage} text="מיחם לשבת" />
          <ShopComponent src={plataImage} text="פלטת שבת" />
        </div>
        <Link to="/shop">לכל המוצרים והזמנות</Link>
      </section>
    </>
  );
}

export default Shop;
