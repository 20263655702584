import React from 'react'

function Footer() {
  return (
    <>
    <footer>
        <p>© כל הזכויות שמורות לאמל"ח שבת לישראל 2023</p>
        {/* <p>בניית האתר יאיר לוי</p> */}
        <p>האתר בתהליך בנייה ועובדים על דפי תנאי שימוש</p>
    </footer>
    </>
  )
}

export default Footer