import React from 'react';
import testimonialsImage from '../Assets/testimonials.png';
import testimonialsThreeImage from '../Assets/testimonials_three.png';
import testimonialsTwoImage from '../Assets/testimonials_two.png';
import { Link } from 'react-router-dom';
import "../App.css"

function Testimonials() {
  return (
    <section className="testimonials_section">
      <h1>כבר מאות קיבלו על עצמם שמירת שבת להצלחת עם ישראל וקיבלו ערכה מתנה!</h1>
      <div className="sub_test">
        <div className="testimonials">
          <img src={testimonialsTwoImage} alt="testimonials" />
        </div>
        <div className="testimonials">
          <img src={testimonialsThreeImage} alt="testimonials" />
        </div>
        <div className="testimonials">
          <img src={testimonialsImage} alt="testimonials" />
        </div>
      </div>
      <Link to="/projects">לקרוא עוד</Link>
    </section>
  );
}

export default Testimonials;
