import React, { useState, useEffect } from 'react';
import ImgOne from '../Assets/projects_photo_one.png';
import ImgTwo from '../Assets/projects_photo_two.png';
import ImgThree from '../Assets/projects_photo_three.png';
import ImgFour from '../Assets/projects_photo_four.png';
import ImgFive from '../Assets/projects_photo_five.png';
import ImgSix from '../Assets/projects_photo_six.png';
import ImgSeven from '../Assets/projects_photo_seven.png';
import ImgEight from '../Assets/projects_photo_eight.png';
import ImgNine from '../Assets/projects_photo_nine.png';
import ImgTen from '../Assets/projects_photo_ten.png';
import ImgEleven from '../Assets/projects_photo_eleven.png';

const ImageSlider = () => {
  const images = [
    ImgOne,
    ImgTwo,
    ImgThree,
    ImgFour,
    ImgFive,
    ImgSix,
    ImgSeven,
    ImgEight,
    ImgNine,
    ImgTen,
    ImgEleven,
  ];

  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setStartIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [startIndex, images.length]);

  const displayedImages = images.slice(startIndex, startIndex + 4).concat(images.slice(0, Math.max(0, 4 - (images.length - startIndex))));

  return (
    <>
    <div className="image-slider center">
      {displayedImages.map((image, index) => (
        <img key={index} src={image} className='projects_photo' alt={`Project ${index + 1}`} />
      ))}
    </div>
    </>
  );
};

export default ImageSlider;
