import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css'
import itemsImage from '../Assets/items-new.png';
import bannerImage from '../Assets/banner.jpg';
import bannerVideo from '../Assets/banner.mp4';

function Welcome() {
  return (
    <section className="welcome_section">
      <div className="sub_welcome">
        <h1>אוצר מתנות חינם לישראל</h1>
        <p>ערכה שלימה לשמירת שבת עם הדרכה טלפונית לכל יהודי שמקבל עליו לשמור שבת!</p>
        {/* <div className="center">
          <img src={itemsImage} alt="items" />
        </div> */}
        <div className='center'>
        <Link to="/donations">לתרום ערכה</Link>
        <Link to="/shop">לקבלת ערכה</Link>
        </div>
      </div>
      <video controls width="480px" height="270px">
        <source src={bannerVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </section>
  );
}

export default Welcome;
