import React, { useState,useEffect } from 'react'
import {Helmet} from "react-helmet"
import '../App.css'
import articleOne from '../Assets/plata.png'
import articleTwo from '../Assets/eau.png'
import articleThree from '../Assets/nerot.png'
import articleFour from '../Assets/kidoush.png'
import articleFive from '../Assets/couvert_hallot.png'
import articleSix from '../Assets/netilat.png'
import articleSeven from '../Assets/sidour.png'
import articleEight from '../Assets/livret.png'

const articlesArray = [
  {title:'פלטת שבת',src:articleOne},
  {title:'מחם לשבת',src:articleTwo},
  {title:'פמוטים לנרות',src:articleThree},
  {title:'כוס קידוש',src:articleFour},
  {title:'כיסוי לחלות',src:articleFive},
  {title:'נטלה לידיים',src:articleSix},
  {title:'סידור מהודר',src:articleSeven},
  {title:'חוברת לימוד',src:articleEight}
];

function Shop() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [order,setOrder] = useState(false);
  const [articles,setArticles] = useState([]);
  const [isSubmit,setIsSubmit] = useState(false);
  const [isLoading,setIsLoading] = useState(false)

  function handleClick(article){

    if (articles.includes(article)) {
      setArticles(articles.filter(item=>item != article))
    } else {
      setArticles([...articles,article])
    }

  };

function Submit(e) {
    e.preventDefault();
    setIsLoading(true);
    // Accessing form elements
    const form = e.target;
    const formData = new FormData(form);

    // Creating an object to store form data
    const data = {};
    formData.forEach((value, key) => {
        data[key] = value;
    });

    // Add one line to the sheet
    fetch("https://sheet.best/api/sheets/5d907ed3-991e-4fee-9f1e-d00f8bd18936", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })
    .then((r) => {
      setIsLoading(false);
      setIsSubmit(true);
      setArticles([]);
    })
    .catch((error) => {
        // Errors are reported there
        console.log(error);
    });
};

  return (
    <div className='shop_page'>
    <h2 style={{color:'white',margin:'20px',textAlign:'center'}}>הזמן בחינם את מה שחסר לך לעשות שבת מושלמת!</h2>
    <div className='shop_navbar'>
      {
        order ? <button onClick={()=>setOrder(false)}>חזרה</button> : <button onClick={()=>setOrder(true)}>הזמנה</button>
      }
      <p>מספר פריטים  {articles.length}</p>
    </div>

    {
      order ? 
      <div className='center'>
      {isSubmit ? <h3 className='form_submit'>בקשתך התקבלה בהצלחה ! הצוות של מתנדבים שלנו יצור איתך קשר בהקדם האפשרי.<br/><br/> אשריכם ישראל ! יהי רצון שבזכות שמירת השבת קודש נתבשר בשורות טובות בקרוב אמן!</h3> : 
      <form className='form_el' onSubmit={(e)=>Submit(e)}>
        <label style={{textAlign:'center',fontSize:'18px'}}>ההזמנה בחינם ללא תשלום כלל !</label>
        <label htmlFor='name'>שם מלא</label>
        <input type='text' name='Name' placeholder='שם מלא'/>
        <label htmlFor='address'>כתובת</label>
        <input type='text' name='Address' placeholder='כתובת'/>
        <label htmlFor='city'>עיר / ישוב</label>
        <input type='text' name='City' placeholder='עיר / ישוב'/>
        <label htmlFor='phone_number'>טלפון</label>
        <input type='tel' name='Phone' placeholder='טלפון'/>
        <label htmlFor='items'>מוצרים להזמנה</label>
        <input type='text' name='Items' placeholder='מוצרים להזמנה' value={articles.join(', ')} />
        <label htmlFor='Status'>האם כבר שומרים שבת או מתחילים ?</label>
        <select name='Status'>
          <option value='מתחיל'>מאוד רוצה להתחיל</option>
          <option value='שומר חלקית'>שומר.ת חלקי.ת ורוצה לקבל על עצמי שבת כהלכתה</option>
          <option value='שומר'>כבר שומרים הרבה זמן</option>
        </select>
        <input style={{fontSize:'16px'}} type='submit' value='הזמן עכשיו!' disabled={isLoading}/>
        {isLoading && <p className='loading'>אנא המתן...</p>}
      </form>
      }
      </div> : 
      <div className='sub_shop shop_container'>
      {
        articlesArray.map(item=>(
          <div className="articles" key={item.index}>
            <div className="img_container"><img src={item.src} alt="img"/></div>
            <h3>{item.title}</h3>
            <button onClick={()=>handleClick(item.title)}>{articles.includes(item.title) ? 'הסר' : 'הוספה'}</button>
        </div>
        ))
      }
      </div>
    }
    <Helmet>
      <title>אמל"ח שבת לישראל - קבלת ערכה</title>
    </Helmet>
    </div>
  )
};

export default Shop;