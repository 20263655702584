import React from 'react';
import creditImage from '../Assets/card.png';
import payboxImage from '../Assets/paybox.png';
import bankImage from '../Assets/bank.png';
import bitImage from '../Assets/bit.png';

function Donation() {
  return (
    <section className="donations_section">
      <h1>הצטרפו למייזם האדיר ותיהיו שותפים לאלפים הלוקחים על עצמם לשמור שבת!</h1>
      <div className="sub_donations">
        <div className="donations_options">
          <div className='center'>
            <img src={creditImage} alt="payment" />
          </div>
          <h3>Card - כרטיס אשראי</h3><br />
          <a href="https://meshulam.co.il/purchase?b=15f2e2b8a8d84f6b648aec2aced6b1f9">לתרומה</a>
        </div>
        <div className="donations_options">
          <div className='center'>
            <img src={payboxImage} alt="payment" />
          </div>
          <h3>PayBox - פייבוקס</h3><br />
          <a href="https://payboxapp.page.link/kR6Nzjqxbp1pdduu6">לתרומה</a>
        </div>
        <div className="donations_options">
          <div className='center'>
            <img src={bankImage} alt="payment" />
          </div>
          <h3>העברה בנקאית</h3><br />
          <p>בנק מיזרחי טפחות<br />סניף 505 חשבון 153095<br />מאיר ביטון</p>
        </div>
        <div className="donations_options">
          <div className='center'><img src={bitImage} alt="payment" /></div>
          <h3>Bit - ביט</h3><br />
          <p className="tel_bit">054-5214473</p>
        </div>
      </div>
    </section>
  );
}

export default Donation;
